import React from 'react';
import formatMoney from './utilities/formatMoney';

class DonationMeter extends React.Component {
  render () {
    const { goal, amount } = this.props;
    const progress = Math.max(Math.min((amount / goal * 100), 100), 6);
    return (
      <div className="meter-container">
        <div className="meter">
          <div className="meter-progress" style={ { width: progress + '%' } }></div>
        </div>
        <span className="meter-label">{ `$${formatMoney(amount, 0)} of $${formatMoney(goal, 0)} donated` }</span>
      </div>
    );
  }
}

export default DonationMeter;