import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import logo from './images/stripe-logo.png';
import powereByStripe from './images/powered_by_stripe.svg';
import Loading from './Loading';
import calcFee from './utilities/calcFee';
const stripeKey = process.env.NODE_ENV === 'production' ? 'pk_live_T56NVtV1RVByHZTIUhCLcByI00t5xQdBVi' : 'pk_test_LXlfMb0jjF8M7HsDij933G6S00kkp9dFrp';
const checkoutName = 'Onward & Upward Grant';
const checkoutBtnLabel = 'Donate';

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

class DonateModal extends React.Component {

  state = {
    customCents: 20000,
    includeFees: true
  };

  componentDidMount = () => {
    if (window.ga) {
      window.ga('send', 'event', 'modal', 'open', 'Donate Modal');
    }
  };

  onDollarsChange = e => {
    const { value } = e.target;
    this.setState({ customCents: value * 100 });
  };

  onToken = (token, amount) => {
    this.setState({ loading: true, success: false, error: '' });
    const body = JSON.stringify({ ...token, amount});
    fetch('/api/charge', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    }).then(handleErrors).then(response => {
      if (window.ga) {
        window.ga('send', 'event', 'donation', 'success', 'Donation Success');
      }
      if (window.fbq) {
        window.fbq('track', 'Donate');
      }
      this.setState({ loading: false, success: true, error: '' });
    }).catch(err => {
      this.setState({ loading: false, success: false, error: err.message });
    });
  };

  render () {
    const { customCents, loading, success, error, includeFees } = this.state;
    let content;
    if (loading) {
      content = <Loading label="Processing..." />;
    } else if (success) {
      content = <p>Thank you for donating!</p>
    } else {
      content = (
        <div className="donation-modal">
          <h3>Select Onward & Upward Grant Donation Amount</h3>
          <p>Donations of <strong>$100</strong> or more include <strong>1 free ticket</strong><br />to our annual <strong>CelebrateGNV</strong> event!</p>
          { error &&
            <span className="error">{ error }</span>
          }
          <div className="donate-options">
            <StripeCheckout
              name={ checkoutName }
              panelLabel={ checkoutBtnLabel }
              image={ logo }
              stripeKey={ stripeKey }
              amount={ includeFees ? calcFee(1000).total : 1000 }
              token={ token => this.onToken(token, includeFees ? calcFee(1000).total : 1000) }>
              <button className="btn donate-option">$10</button>
            </StripeCheckout>
            <StripeCheckout
              name={ checkoutName }
              panelLabel={ checkoutBtnLabel }
              image={ logo }
              stripeKey={ stripeKey }
              amount={ includeFees ? calcFee(2000).total : 2000 }
              token={ token => this.onToken(token, includeFees ? calcFee(2000).total : 2000) }>
              <button className="btn donate-option">$20</button>
            </StripeCheckout>
            <StripeCheckout
              name={ checkoutName }
              panelLabel={ checkoutBtnLabel }
              image={ logo }
              stripeKey={ stripeKey }
              amount={ includeFees ? calcFee(3500).total : 3500 }
              token={ token => this.onToken(token, includeFees ? calcFee(3500).total : 3500) }>
              <button className="btn donate-option">$35</button>
            </StripeCheckout>
            <StripeCheckout
              name={ checkoutName }
              panelLabel={ checkoutBtnLabel }
              image={ logo }
              stripeKey={ stripeKey }
              amount={ includeFees ? calcFee(10000).total : 10000 }
              token={ token => this.onToken(token, includeFees ? calcFee(10000).total : 10000) }>
              <button className="btn donate-option">$100</button>
            </StripeCheckout>
            <div className="custom-container">
              <span className="custom-label">Custom Amount:</span>
              <input className="text-input donate-amnt" placeholder="Custom" type="text" value={ customCents / 100 } onChange={ this.onDollarsChange } />
              <StripeCheckout
                name={ checkoutName }
                panelLabel={ checkoutBtnLabel }
                image={ logo }
                stripeKey={ stripeKey }
                amount={ includeFees ? calcFee(customCents).total : customCents }
                token={ token => this.onToken(token, includeFees ? calcFee(customCents).total : customCents) }>
                <button className="btn donate-option amnt-btn">Donate</button>
              </StripeCheckout>
            </div>
            <label className="field checkbox include-fees">
              <input className="checkbox-input" type="checkbox" onChange={ e => this.setState({ includeFees: e.target.checked }) } checked={ includeFees } />
              <div className="check-container">
                <svg className="icon-check" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 768">
                  <path fill="#fff" d="M288 517.5l339-339 45 45-384 384-178.5-178.5 45-45z"></path>
                </svg>
              </div>
              <span className="field-label">Include processing fee in donation</span>
            </label>
            <img className="powered-by" src={ powereByStripe } />
          </div>
        </div>
      );
    }
    return content;
  }
}

export default DonateModal;