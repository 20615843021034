import _ from 'lodash';
import React, { Component } from 'react';
import * as firebase from "firebase/app";
import "firebase/database";
import 'whatwg-fetch';
import './sass/main.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Waypoint } from 'react-waypoint';
import ReactTooltip from 'react-tooltip';
import logo from './images/logo.svg';
import sgnvLogo from './images/sgnv-logo.svg';
import aboutImg from './images/about-photo.jpg';
import twitterLogo from './images/twitter-logo.svg';
import fbLogo from './images/fb-logo.svg';
import igLogo from './images/ig-logo.svg';
import gdaImg from './images/gda.jpg';
import joshImg from './images/josh.jpg';
import { Link, scrollSpy } from 'react-scroll'
import DonateModal from './DonateModal';
import Modal from './Modal';
import DonationMeter from './DonationMeter';
import formatMoney from './utilities/formatMoney';
import calcFee from './utilities/calcFee';
import hutchisonLogo from './images/hutchison-logo.svg';
import sharpspringLogo from './images/sharpspring-logo.svg';
import trimarkLogo from './images/trimark-logo.svg';

const donationGoal = 5000;

firebase.initializeApp({
  apiKey: "AIzaSyCrvRHfe3WYa_r3FGVnCILpOmd1YS1PyDk",
  authDomain: "josh-greenberg-day.firebaseapp.com",
  databaseURL: "https://josh-greenberg-day.firebaseio.com",
  projectId: "josh-greenberg-day",
  storageBucket: "josh-greenberg-day.appspot.com",
  messagingSenderId: "698231036745"
});
const db = firebase.database();

class App extends Component {

  state = {
    videoModal: false,
    donateModal: false,
    emailSuccess: false,
    email: '',
    activeSection: 'about',
    totalPercent: 0
  };

  componentDidMount = () => {
    scrollSpy.update();
    const donationsRef = db.ref('donations');
    donationsRef.on('value', this.calcDonationTotals);
    donationsRef.orderByChild('created').startAt(Date.now() / 1000).on('child_added', this.onDonation);
    toast('First 100 donations will receive a free Onwards & Upwards t-shirt!', {
      position: toast.POSITION.BOTTOM_CENTER,
      className: 'jg-toast',
      progressClassName: 'orange-bar',
      autoClose: 15000,
      delay: 2000
    });
  }

  calcDonationTotals = snapshot => {
    const donations = snapshot.val();
    let donationsTotal = 0;
    _.each(donations, donation => {
      const fee = calcFee(donation.amount).fee;
      donationsTotal += (donation.amount - fee);
    });
    donationsTotal = donationsTotal / 100;
    const totalPercent =  Math.round(donationsTotal / donationGoal * 100);
    this.setState({ donations, donationsTotal, totalPercent });
  };

  onDonation = snapshot => {
    const donation = snapshot.val();
    const name = donation.name || 'Someone';
    const msg = `${name} just donated $${formatMoney((donation.amount - calcFee(donation.amount).fee) / 100, 0)}!`;
    toast(msg, {
      position: toast.POSITION.BOTTOM_CENTER,
      className: 'jg-toast',
      progressClassName: 'orange-bar',
      closeButton: false,
      autoClose: 8000
    });
  };

  onCloseVideoModal = () => {
    this.setState({ videoModal: false });
  };

  onCloseDonateModal = () => {
    this.setState({ donateModal: false });
  };

  onEmailSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    fetch('/api/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    }).then(res => {
      this.setState({ emailSuccess: true });
    });
    return false;
  };

  render() {
    const { videoModal, donateModal, emailAddress, emailSuccess, donationsTotal, activeSection, totalPercent } = this.state;
    return (
      <div className="wrapper">
        <ToastContainer />
        <ReactTooltip effect="solid" />
        { videoModal &&
          <Modal className="video" onCloseClick={ this.onCloseVideoModal }>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ejUaIKBRLb0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
          </Modal>
        }
        { donateModal &&
          <Modal className="donate" onCloseClick={ this.onCloseDonateModal }>
            <DonateModal />
          </Modal>
        }
        <nav className="navigation" role="navigation">
          <div className="inner">
            <img src={ logo } className="logo" alt="logo" />
            <ul>
              <li className={ activeSection === 'about' ? 'active' : '' }>
                <Link to="about" offset={-80} spy={true} smooth={true} duration={500}>
                  <span>01</span>His Legacy
                </Link>
              </li>
              <li className={ activeSection === 'scholarships' ? 'active' : '' }>
                <Link to="scholarships" offset={-80} spy={true} smooth={true} duration={500}>
                  <span>02</span>Scholarships
                </Link>
              </li>
              <li className={ activeSection === 'inTouch' ? 'active' : '' }>
                <Link to="in-touch" offset={-80} spy={true} smooth={true} duration={500}>
                  <span>03</span>Stay in touch
                </Link>
              </li>
            </ul>
            <div className="donate-container">
              <span className="percent-goal"><span className="percent">{ totalPercent + '%' }</span><br />Funded</span>
              <button onClick={ () => this.setState({ donateModal: true }) } className="donate btn" data-tip="First 100 donations receive a free tshirt!" data-place="bottom">Donate Now</button>
            </div>
          </div>
        </nav>
        <Waypoint onEnter={ () => this.setState({ activeSection: 'about' }) } fireOnRapidScroll>
          <div>
            <header className="section hero">
              <div className="inner">
                <div className="row">
                  <div className="col-md-6">
                    <h1>
                      JOSH GREENBERG DAY, APRIL 17
                      <small>
                        Josh Greenberg was a startup pioneer who changed the landscape of Gainesville’s entrepreneurship scene forever. As a selfless visionary and leader, Josh elevated what it meant to create and give - And on April 17, our growing community gathers to celebrate his spirit and mission: to continue to move Gainesville’s needle and create a city and community where startups thrive. Onward & Upward!
                      </small>
                    </h1>
                  </div>
                  <div className="col-md-6">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ejUaIKBRLb0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </header>
            <main id="about" className="about
              section" role="main" >
              <div className="card card--about">
                <img src={ aboutImg } alt="About Josh Greenberg" />
                <h2 className="section-header">
                  <small>01 —</small>
                  HIS LEGACY
                </h2>
                <p>
                  Josh Greenberg was well known as the co-founder and CTO of Grooveshark, one of world’s first music-on-demand streaming services. At its peak, Grooveshark served 40 million listeners monthly and employed 145 local professionals. What most don’t know about this Gainesville icon is how he served his community - A selfless model of giving, Josh always made time for others.
                </p>

                <p>
                  Josh worked tirelessly to build and nurture Gainesville’s startup community. He served on Gainesville’s Tech Council and acted as a mentor to countless startup founders. Understanding the importance of community and growth, Josh was insistent on improving Gainesville’s cultural infrastructure and building local companies.
                </p>

                <p>
                  Interested in learning more about Josh’s story? Check out our blog <a href="https://startupgnv.com/blog/2019/03/14/the-grooveshark-story-pt-1-the-beginning/" target="_blank">HERE</a>.
                </p>
              </div>
            </main>
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.setState({ activeSection: 'scholarships' }) } fireOnRapidScroll>
          <section id="scholarships" className="section scholarships">
            <div className="inner">
              <h2 className="section-header">
                <small>02 —</small>
                Scholarships
              </h2>
              <p>
                Josh’s time with us what cut short but together we keep his entrepreneurial spirit alive. startupGNV and Gainesville Dev Academy will honor Josh Greenberg Day with two prestigious award programs for aspiring entrepreneurs and software engineers.
              </p>
              <div className="card content">
                <div className="scholarships-container">
                  <div className="scholarship">
                    <img className="scholarship-img" src={ gdaImg } />
                    <h3>GDA SCHOLARSHIP</h3>
                    <p>
                      A founder of <a href="http://gainesvilledevacademy.com/" className="gda-link">Gainesville Dev Academy</a>, Josh Greenberg advocated for people of all skill levels to learn to become better programmers. In honor of Josh and his legacy, Duncan Kabinu and the Dev Academy will recognize 2 participants each year full admission through its 2 course boot camp program, a value of over $7,000 for each participant. These awards will be presented during our annual CelebrateGNV event.
                    </p>
                  </div>
                  <div className="scholarship">
                    <img className="scholarship-img" src={ joshImg } />
                    <h3>ONWARD & UPWARD GRANT</h3>
                    <p>
                      The inaugural Onward & Upward Grant epitomizes Josh's spirit of entrepreneurship, community, and giving. This crowdfunded $5,000 grant for first-time startup founders will be awarded to one early-stage Gainesville company at the annual CelebrateGNV event. Donate now to support this new program – any contributions above $5,000 help ensure Josh's legacy lives on through future years of this grant.
                    </p>
                    <DonationMeter goal={ donationGoal } amount={ donationsTotal } />
                    <button onClick={ () => this.setState({ donateModal: true }) } className="donate btn">Donate Now</button>
                  </div>
                </div>
                <div className="apply">
                  <h3>APPLY</h3>
                  <p>
                    Interested in learning more or applying to either of these awards? Sign up to our list below and we’ll let you know when we’re ready to go!
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Waypoint>
        <Waypoint onEnter={ () => this.setState({ activeSection: 'inTouch' }) } fireOnRapidScroll>
          <section className="section in-touch">
            <div id="in-touch" className="inner">
              <h2 className="section-header">
                <small>03 —</small>
              </h2>
              <h3>Sign up and stay in touch</h3>
              <p>Interested in applying for a scholarship or just want more info? Leave your email below:</p>
              { !emailSuccess &&
                <form onSubmit={ this.onEmailSubmit }>
                  <label className="field">
                    <input className="text-input" type="text" name="email" value={ emailAddress } placeholder="Email Address" onChange={ ({ target: { value } }) => this.setState({ email: value }) } />
                    <button type="submit" className="btn">Subscribe</button>
                  </label>
                </form>
              }
              { emailSuccess &&
                <div>
                  <p>Thank you for subscribing! Expect to hear more news about the Onward and Upward and Gainesville Dev Academy scholarships soon.</p>
                  <p>In the meantime, consider donating!</p>
                  <button onClick={ () => this.setState({ donateModal: true }) } className="donate btn">Donate Now</button>
                </div>
              }
            </div>
          </section>
        </Waypoint>
        <div className="footer">
          <div className="inner">
            <p>Created with <svg className="heart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="m417.59 527.35c-.653-.644-1.555-.965-2.707-.965-.319 0-.644.056-.976.167-.332.112-.64.262-.926.452-.285.189-.531.367-.737.533-.206.166-.401.343-.586.529-.185-.187-.381-.363-.586-.529-.206-.166-.451-.344-.737-.533-.285-.189-.594-.34-.926-.452-.332-.112-.657-.167-.976-.167-1.152 0-2.054.322-2.707.965-.653.644-.98 1.536-.98 2.678 0 .348.06.706.181 1.074.121.369.258.683.413.942.154.259.329.513.524.759.195.247.338.416.428.51.09.093.161.161.212.202l4.813 4.687c.093.094.206.14.339.14.134 0 .247-.047.339-.14l4.805-4.672c1.178-1.189 1.766-2.356 1.766-3.504 0-1.142-.327-2.035-.98-2.678" fill="#fff" transform="translate(-403.7-524.37)"/></svg> by</p>
            <a href="https://startupgnv.com" target="_blank">
              <img className="sgnv-logo" src={ sgnvLogo } />
            </a>
            <p>with support from our Annual Partners of Startup Programs:</p>
            <div className="sponsor-logos">
              <img className="sponsor-logo" src={ sharpspringLogo } />
              <img className="sponsor-logo" src={ hutchisonLogo } />
              <img className="sponsor-logo" src={ trimarkLogo } />
            </div>
          </div>
          <div className="social">
            <a className="social-link" href="https://twitter.com/startupgnv" target="_blank">
              <img className="social-logo" src={ twitterLogo } />
            </a>
            <a className="social-link" href="https://www.facebook.com/startupgnv" target="_blank">
              <img className="social-logo" src={ fbLogo } />
            </a>
            <a className="social-link" href="https://www.instagram.com/startupgnv" target="_blank">
              <img className="social-logo" src={ igLogo } />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
