export default function calcFee(amnt) {
    const _fee = { Percent: 2.9, Fixed: 30 };
    const total = (amnt + _fee.Fixed) / (1 - parseFloat(_fee.Percent) / 100);
    const fee = total - amnt;

    return {
        amount: amnt,
        fee: Math.round(fee),
        total: Math.round(total)
    };
}