import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {

  static propTypes = {
    className: PropTypes.string
  };

  onModalClick = e => {
    const { onCloseClick } = this.props;
    if (!this.modalContent.contains(e.target)) {
      onCloseClick();
    }
  }

  render () {
    const { children, onCloseClick = () => {}, className = "" } = this.props;
    return (
      <div className={ 'modal ' + className } onClick={ this.onModalClick }>
        <div className="modal-content" ref={ ref => this.modalContent = ref }>
          <div className="close" onClick={ onCloseClick }>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 768 768">
              <path fill="#fff" d="M607.5 205.5l-178.5 178.5 178.5 178.5-45 45-178.5-178.5-178.5 178.5-45-45 178.5-178.5-178.5-178.5 45-45 178.5 178.5 178.5-178.5z"></path>
            </svg>
          </div>
          { children }
        </div>
      </div>
    );
  }
}

export default Modal;